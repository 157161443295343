* {
  margin-bottom: 0;
}

html {
  scroll-behavior: smooth;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #333;
  z-index: 1000; /* Ensure it's above other content */
}

.menu-icon {
  display: none; /* Hide menu icon by default */
}

.navbar.open .menu-icon {
  display: block; /* Show menu icon when navbar is open */
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}

.navbar li {
  padding: 0 15px;
}

.navbar li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navbar li a:hover {
  background-color: #ddd;
  color: black;
}

.navbar li a.active {
  color: rgb(255, 218, 53);
  text-decoration: underline;
  text-underline-offset: 4px;
}

.component {
  margin: 50px 8rem 50px 8rem;
  padding: 40px;
  align-items: flex-start;
  overflow: auto;
  display: flex;
}

.sub-component {
  flex: 1;
}

.profile_pic {
  height: 400px;
  width: 300px;
  float: right;
  margin-right: 6.5rem;
  border-radius: 6%;
  z-index: 2;
  vertical-align: middle;
  box-shadow: 10px 14px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow effect */
}

.intro-text {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 600;
  color: #6d7379;
  font-size: xx-large;
}

.name-title {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 800;
  color: rgb(73, 80, 87);
  margin: 0;
  font-size: 4rem;
}

.sub-title {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 500;
  color: #1e202e;
  margin-top: 10px;
  font-size: 2rem;
}

.achievements-title,
.experience-title,
.skills-title,
.about-me-title {
  text-align: center;
  font-size: 34px;
  font-family: Helvetica, sans-serif;
  font-weight: 600;
  color: #ff9804;
  margin-bottom: 40px;
  text-decoration: underline;
  text-underline-offset: 10px;
}

.experience-title {
  /* color: rgb(255, 122, 87); */
  color: rgb(44, 138, 226);
}
.skills-title {
  color: rgb(255, 122, 87);
}

.about-me-title {
  color: rgb(36, 122, 172);
}

.achievements,
.experience,
.skills {
  margin: 50px 30px;
}
.about-me {
  margin: 50px 40px;
}

.aws-img {
  width: 200px;
  height: 200px;
}

.insta-img,
.google-img {
  width: 300px;
  height: 200px;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 40px; /* Add whitespace on the sides */
}

.image-item {
  text-align: center;
}

.image-item img {
  max-width: 100%; /* Ensure each image occupies one-third of the container width minus the margin */ /* Maintain aspect ratio */
}

.image-item p {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #6e7883;
}

.exp-container {
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 5rem;
}

.infy-img {
  box-shadow: 0px 4px 6px #1e202e;
}

.infy-img-container {
  width: 100%;
  flex: 35%;
}

.infy-text {
  flex: 65%;
}

.infy-title {
  font-family: Helvetica, sans-serif;
  font-size: 24px;
  margin: 0;
}

.infy-sub-title {
  font-family: Helvetica, sans-serif;
  margin-top: 5px;
  opacity: 0.7;
}

.java-img {
  height: 140px;
  width: 180px;
}

.skills-container,
.about-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  text-align: center;
}

.skills-item {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
}

.skills-item:first-child {
  margin-bottom: 50px;
}

.skills-item ul {
  list-style: none;
  line-height: 1.5;
  opacity: 0.8;
  font-size: 14px;
}

.skills-img {
  width: 140px;
  height: 140px;
  /* box-shadow: 11px 10px 18px 1px #3c3d41; */
}

.about-item {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
}

.about-img {
  width: 250px;
  float: left;
  margin-right: 20px;
}

.about-item-text {
  font-size: 15px;
  opacity: 0.8;
  margin: 0 8px;
}

footer {
  background-color: #202020;
  margin: 0;
  color: rgba(255, 255, 255, 0.979);
  display: flex;
  flex-wrap: nowrap;
}

footer div {
  flex: 1;
  padding: 10px;
  margin: 12px 30px 25px 30px;
  text-align: center;
}

.linkedin-link {
  color: azure;
}

.linkedin-link:hover {
  color: rgb(208, 247, 247);
  text-decoration: underline;
}

footer div:first-child {
  align-content: center;
}

footer div:nth-child(2) {
  text-align: left;
}

@media screen and (max-width: 1024px) {
  .component {
    margin: 50px 6rem 50px 6rem;
  }

  .aws-img {
    width: 200px;
    height: 200px;
  }

  .insta-img {
    width: 300px;
    height: 200px;
  }

  .google-img {
    width: 300px;
    height: 200px;
  }
  .image-item p {
    font-size: 16px;
  }

  .achievements,
  .experience,
  .skills {
    margin: 30px 15px;
  }

  .about-me {
    margin: 30px 15px;
  }
}

@media screen and (max-width: 768px) {
  section {
    max-width: 100%;
  }
  .component {
    margin: 20px 10px;
    padding: 10px;
  }

  .intro-text,
  .sub-title {
    font-size: large;
  }

  .name-title {
    font-size: xx-large;
  }

  .profile_pic {
    width: 160px;
    height: auto;
    margin: 0;
  }

  .achievements-title,
  .experience-title,
  .skills-title,
  .about-me-title {
    text-align: center;
    font-size: 24px;
    font-family: Helvetica, sans-serif;
    font-weight: 600;
    color: #ff9804;
    margin-bottom: 20px;
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  .image-item p {
    margin: 6px 0 16px 0;
    font-size: 14px;
    font-weight: 600;
    color: #6e7883;
  }

  .image-container {
    margin: 0 10px;
  }

  .achievements,
  .experience,
  .skills {
    margin: 18px 5px;
  }

  .about-me {
    margin: 18px 8px;
  }

  .infy-img {
    width: 150px;
    height: auto;
  }

  .infy-title {
    font-size: 20px;
  }

  .infy-exp {
    font-size: small;
  }

  .exp-container {
    margin: 1rem 2rem;
  }

  .aws-img {
    width: 150px;
    height: 150px;
  }

  .insta-img,
  .google-img {
    width: 210px;
    height: 140px;
  }

  .skills-img {
    width: 70px;
    height: auto;
  }

  .java-img {
    width: 125px;
    height: 100px;
  }

  .skills-item ul {
    font-size: small;
    line-height: 1.15;
    padding: 0 15px;
    margin: 0;
  }

  .navbar ul {
    display: none; /* Hide the navigation links by default */
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background-color: #333;
    position: absolute;
    top: 60px;
    left: 0;
  }

  .navbar.open ul {
    display: flex; /* Show the navigation links when navbar is open */
  }

  .navbar li {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }

  .navbar li a {
    padding: 0;
    font-size: medium;
  }

  .navbar li a:hover {
    background-color: #202020;
    color: rgb(255, 218, 53);
  }

  .menu-icon {
    display: block; /* Show the menu icon for mobile */
    cursor: pointer;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
  }

  .navbar.open .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .navbar.open .bar:nth-child(2) {
    opacity: 0;
  }

  .navbar.open .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .image-item {
    width: calc(
      100% - 10px
    ); /* On smaller screens, each image occupies half of the container width minus the margin */
  }

  .skills-item {
    flex-basis: calc(
      90% - 10px
    ); /* Each grid item takes up 50% of the container width minus the padding */
    margin: 0 10px 20px; /* Add margin to create spacing between grid items */
    display: flex;
  }

  .skills-item:first-child {
    margin-bottom: 10px;
  }

  .skills-item img {
    flex: 25%;
  }

  .skills-img {
    width: 100px;
    height: 100px;
    margin-left: 0 4px 0 6px;
  }

  .about-item {
    text-align: center;
  }

  .about-item-text {
    display: block;
    font-size: 12px;
  }
  .about-img {
    float: none;
    margin: 0;
  }

  footer {
    display: inline-block;
    font-size: 14px;
  }

  footer div {
    padding: 0;
    margin: 0 20px 5px 20px;
    text-align: left;
  }
  footer h2 {
    font-size: 18px;
  }
}

@media screen and (min-width: 1700px) {
  .component {
    margin: 80px 15rem 80px 15rem;
  }

  .aws-img {
    width: 380px;
    height: 380px;
  }

  .insta-img,
  .google-img {
    width: 575px;
    height: 420px;
  }

  .image-item p {
    font-size: 26px;
  }
  .exp-container {
    margin: 3rem 8rem 3rem 12rem;
  }

  .infy-img {
    width: 400px;
    height: 225px;
  }

  .achievements,
  .experience,
  .skills {
    margin: 40px 35px;
  }

  .about-me {
    margin: 30px 15px;
  }
}
